//::-webkit-input-placeholder { /* Chrome/Opera/Safari */
//  color: var(--m-form__placeholder---color);
//}
//
//::-moz-placeholder { /* Firefox 19+ */
//  color: var(--m-form__placeholder---color);
//}
//
//:-ms-input-placeholder { /* IE 10+ */
//  color: var(--m-form__placeholder---color);
//}
//
//:-moz-placeholder { /* Firefox 18- */
//  color: var(--m-form__placeholder---color);
//}

.m-form {

  a {
    //color: var(--m-form__a---color);
    //text-decoration: underline;
    //font-weight: bolder;
  }
}

.m-form__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.m-form__group--prefixed {
}

.m-form__group--suffixed {
}

.m-form__prefix {
  margin-right: 20px;
  line-height: 1;

  img {
    height: 80px;
  }
}

.m-form__suffix {
  margin-left: 20px;
  line-height: 1;

  img {
    height: 40px;
  }
}

.m-form__label {
  flex-grow: 1;
  display: block;
  width: 100%;
}

.m-form__control {

  &[type="text"],
  &[type="tel"],
  &[type="email"],
  &[type="number"] {
    //display: block;
    width: 100%;
    //border: 1px solid var(--m-form__control---border);
    //font-size: $font-size--xs;
    //color: var(--m-form__control---color);
    //background-color: var(--m-form__control---bg);
    //padding: 15px 25px;
  }


  &[type="checkbox"] {
    @include sr-only;

    + .m-form__label {
      position: relative;

      span {
        padding-left: 42px;
      }

      &::before {
        position: absolute;
        top: 5px;
        content: '';
        display: block;
        width: $spacing--default;
        height: $spacing--default;
        //border: var(--m-form__checkbox---border) 1px solid;
        //background-color: var(--m-form__checkbox---bg);
      }
    }
  }

  &[type="checkbox"]:checked {

    + .m-form__label {
      &::after {
        position: absolute;
        content: '✓';
        top: 0;
        left: 8px;
      }
    }
  }
}

select {
  display: block;
  width: 100%;
  //border: 1px solid var(--m-form__select---border);
  //font-size: $font-size--xs;
  //color: var(--m-form__select---color);
  //background-color: var(--m-form__select---bg);
  //padding: 15px 25px;
}

.m-form__ {
}

.m-form__message {
  display: block;
  width: 100%;
  //font-size: $font-size--xs;
}

.m-form__message--error {
  //color: var(--m-form__message--error---color);
}
