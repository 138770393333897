//@function lightColor($color, $opacity: .2) {
//  @return rgba($color, $opacity);
//}
table {
  thead, tbody, td, tr {
    background: transparent;
    border: 0 none;
  }
}

.sr-only {
  @include sr-only;
}

.grid__row {
  @include make-row();
  margin-bottom: $spacing--default;
}

.grid__col {
  @include make-col-ready();
  width: 100%;
}

.grid_col--offset-1 {
  @include make-col(12);

  @include media-breakpoint-up(lg) {
    //@include make-col-offset(1);

    &.grid_col {
      //@include make-col(10);
    }
  }
}

.grid_col--offset-2 {
  @include make-col(12);

  @include media-breakpoint-up(lg) {
    //@include make-col-offset(2);
    &.grid_col {
      //@include make-col(8);
    }
  }
}

.grid_col--offset-3 {
  @include make-col(12);

  //@include make-col-offset(3);

  &.grid_col {
    //@include make-col(6);
  }
}

.h-completed {
  display: flex;
  align-content: center;
  //font-weight: bold;
  //margin-bottom: $spacing--default ;

  .h-big {
    //font-size: $font-size--xl;
    margin-right: $spacing--default*2;
  }

  .h-code {
    display: inline-block;
    //color: $color--white;
    //background-color: #4CAF50;
    //padding: 3px 25px;
    //border-radius: 16px;
  }
}

.text-right {
  text-align: right;
}
