.o-cards {
  list-style: none;
  margin: 0;
  @include make-row();
  padding: 0;

  .m-card {
    @include make-col-ready();
    @include make-col(12);
    margin-bottom: $grid-gutter-width;
  }
}

.o-cards--fill {
  display: flex;
  flex-wrap: wrap;

  .m-card {
    padding: 0 $grid-gutter-width / 2;

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(2);
    }

    .m-card__title {

    }
    .m-card__trigger {
      padding: 40px;
      height: 100%;
    }
  }
}

.o-cards--halves {
  .m-card {
    @include media-breakpoint-up(xl) {
      @include make-col(6);
    }
  }
}
.o-cards--thirds {
  .m-card {
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(4);
    }
  }
}

.o-cards--quarters {
  .m-card {
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(3);
    }
  }
}
.o-cards--fifths {
  .m-card {
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(2);
    }
  }
}
