.a-headline {
  position: relative;
  //font-weight: bold;
  //color: var(--a-headline---color);
}

.a-headline--1 {
  //font-size: $font-size--l;
  //margin-top: 0;
  //margin-bottom: 42px;
  //line-height: 1.2;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    display: inline-block;
  }

  img {
    height: 80px;
    margin-right: $spacing--default;
  }
}

.a-headline--completed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //color: var(--a-headline--completed---color);
  //font-size: $font-size--xl;
  //font-weight: bold;
  //margin-bottom: $spacing--default*2;

  @include media-breakpoint-up(xl) {
    //font-size: 90px;
  }

  img {
    width: 80px;
    margin-right: $spacing--default;
    @include media-breakpoint-up(xl) {
      width: 150px;
    }
  }
}

.a-headline__action {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 30px;

  img {
    width: 30px;
    height: 30px;
  }
}


.m-select {
  position: absolute;
  top: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 50;
  //background-color: var(--m-select---bg);
  //font-size: $font-size--default;
  //box-shadow: -7px 9px 15px var(--m-select---shadow);
}

.m-select__item {
  //padding: 5px 25px;

  &:nth-child(even) {
    //background-color: var(--m-select__item--even---bg);
  }
}


.m-select__link {
  display: block;
  //color: var(--m-select__link---color);
  //text-decoration: none;
  //font-weight: normal;


  .m-select__item--selected &{
    //color: var(--m-select__item--selected---color);
    //font-weight: bold;
  }
}
