.o-footer {
}


.o-footer__meta {
  padding: $spacing--default 0;

}
.o-footer__meta-inner {
  @extend %space-between-centered;
  flex-wrap: wrap;
}

.o-footer__contact {

  a {
  }
}

.o-footer__footnotes {
  margin-top: 5px;
}

.o-footer__contact-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.o-footer__contact-list-item {
  display: flex;
  align-content: center;

  &:not(:last-child) {
    margin-right: $spacing--default;
  }
}

.o-footer__social {
}

.o-footer__social-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.o-footer__social-list-item {
  &:not(:last-child) {
    margin-right: $spacing--default;
  }

  a {
  }
}

.o-footer__legal {

   a {
   }
}
.o-footer__legal-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.o-footer__legal-nav {
  margin-left: 25px;
}

.o-footer__legal-nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.o-footer__legal-nav-list-item {
  &:not(:last-child)::after {
    content: '|';
    padding: 0 15px;
  }
  a {
  }
}
