.m-product-card {
}

.m-product-card_inner {
  //box-shadow: 0 3px 15px var(--m-product-card_inner---shadow);
  //border-radius: 5px;
  //background-color: var(--m-product-card_inner---bg);
  padding: $spacing--default;
  display: flex;
}

.m-product-card__image {
  margin-right: $spacing--default;
}

.m-product-card__content {
  width: 100%;
}

.m-product-card__headline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  //font-size: $font-size--l;
  //color: var(--m-product-card__headline---color);
  //font-weight: bold;
  margin-bottom: 8px;
  margin-top: 0;

  [class^='a-icon'] {
    color: var(--m-product-card__headline---color);
  }
}

.m-product-card__body {

  p:last-child {
    margin-bottom: 0;
  }
}
