.a-icon {
  position: relative;
}
.a-icon--plus {
  &::after {
    //background-color: $color--white;
    display: flex;
    justify-content: center;
    align-items: center;
    content: '+';
    width: 28px;
    height: 28px;
    //border: 3px solid $color--tertiary;
    //border-radius: 50%;
    //color: $color--primary;
    position: absolute;
    bottom: -5px;
    right: -5px;
  }
}
