@import "global";

#myopus-eu {
  width: 100%;

  * {
    box-sizing: border-box;
  }

  @import "utilities";

  // layout
  @import "layout/_index";

  // atoms
  @import "atoms/_index";

  // molecules
  @import "molecules/_index";

  // organisms
  @import "organisms/_index";
}
