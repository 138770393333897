.m-progress {
  position: relative;
  height: 25px;
  display: block;
  width: 100%;
  //border-top-left-radius: 9px;
  //border-top-right-radius: 9px;
}

.m-progress__status {
  display: block;
  height: 100%;
  //border-top-left-radius: 7px;
  background-color: var(--m-progress__status---bg);
  position: relative;
  overflow: hidden;
}
