.m-cart {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

}

.m-cart__headline {
}

.m-cart__row {
  tbody &:nth-child(odd) {
    //border-radius: 5px;
    //background-color: var(--color--white);

    td:first-child {
      //border-top-left-radius: 5px;
      //border-bottom-left-radius: 5px;
    }

    td:last-child {
      //border-top-right-radius: 5px;
      //border-bottom-right-radius: 5px;
    }
  }
}

.m-cart__col {
  vertical-align: top;
  padding: $spacing--default;
}

.m-cart__col--image {
}

.m-cart__col--description {
  text-align: left;
}

.m-cart__col--price {
  text-align: right;
}

.m-cart__actions {
  display: flex;
  align-content: center;
  margin-top: 36px;

  select {
    width: auto;
    margin-right: $spacing--default;
  }

  .a-icon__trash {
    font-size: 1.6em;
    //color: var(--m-cart__actions---color);
  }


}

.m-cart__col--sum {
  text-align: right;
  font-weight: bold;
}

@media screen {
  @include media-breakpoint-down(md) {
    .m-cart {
      display: block;

      thead, tbody, tfoot, th, td, tr {
        display: block;
      }
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }


    td {
      &.m-cart__col--price {
        text-align: left;
        padding-top: $spacing--default/2;
        padding-bottom: $spacing--default/2;
      }

      &[data-mobile-prefix]::before {
        content: attr(data-mobile-prefix) ": ";
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }
}
