.m-btn-group {
  margin: 0 $grid-gutter-width/2*-1;
  margin: 0;

  .a-btn {
    margin-right: $spacing--default;
    margin-bottom: $spacing--default;
    word-wrap: break-word;
  }

  @include media-breakpoint-up(md) {
    .a-btn:not(:last-child) {
      margin-right: $spacing--default;
    }
  }
}

.m-btn-group--right {
  display: flex;
  flex-direction: column;
  text-align: center;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
  }
}

.m-btn-group--left {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
}

.m-btn-group--block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 auto;

  .a-btn {
    display: block;
    width: 100%;
    text-align: center;
  }
}
