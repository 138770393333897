
.layout {
}

.layout__container {
  width: 100%;
  //@include make-container();
  //max-width: 1811px;
}

.layout__header {
  //background-color: var(--layout__header---bg);
  //color: var(--layout__header---color);
  //padding: 25px 0;
}

.layout__header-inner {
  @extend %space-between-centered;
  flex-wrap: wrap;
}

.layout__logo {
}

.layout__headline {
  //text-transform: uppercase;
  //font-weight: bold;
  //font-size: $font-size--xl;

  a {
    //color: var(--color--primary);
    //text-decoration: none;

    &:hover, &:focus {
      //color: var(--color--primary);
    }
  }
}

//.layout__nav {
//  span {
//    display: flex;
//    justify-content: flex-end;
//    align-content: center;
//  }
//
//  img {
//    margin-left: 10px;
//    width: 25px;
//  }
//}

.layout__main {
  //margin: 30px 0;
}

.layout__footer {
}
