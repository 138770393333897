.o-main {
}

.o-main__row {
  @include make-row();
}

.o-main__column {
  @include make-col-ready();
  @include make-col(12);
    padding: 0 $spacing--default;

  @include media-breakpoint-up(xl) {
    padding: 0 $spacing--default*2;
  }
}

.o-main__column--left,
.o-main__column--right {

  @include media-breakpoint-up(lg) {
    @include make-col(5.8);
  }
}

.o-main__column--right {
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: left;
    align-self: center;
  }
}

.o-main__divider {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
    @include make-col-ready();
    @include make-col(0.4);
    position: relative;
    &::after {
      position: absolute;
      top: 10%;
      left: 50%;
      right: 50%;
      content: '';
      border: 2px solid rgba(#AAB0BB, .18);
      width: 2px;
      height: 80%;
    }
  }
}
