.o-overview {}
.o-overview__headline {
  a {
  }
}
.o-overview__items {
  @include make-row();
  list-style: none;
  padding: 0;
}
.o-overview__item {
  @include make-col-ready();
  @include make-col(12);
  margin-bottom: $grid-gutter-width;

  @include media-breakpoint-up(lg) {
    @include make-col(6);
  }
}
